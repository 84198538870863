
import { ControlledMenu, MenuItem, useHover, useMenuState } from '@szhsin/react-menu';
import '@szhsin/react-menu/dist/index.css';
import '@szhsin/react-menu/dist/transitions/zoom.css';
import { ChevronDown } from 'lucide-react';
import React, { useRef } from 'react';

import { Link, usePathname } from '@/navigation';

import { Visible } from './visible';

const HoverMenu = (props: HoverMenuProps) => {
  const ref = useRef(null);
  const [menuState, toggle] = useMenuState({ transition: true });
  const { anchorProps, hoverProps } = useHover(menuState.state, toggle);
  const { menus, triggerProps, menuItemProps, activeTriggerProps, activeMenuItemProps } = props;
  const { trigger, href, items } = menus;
  const pathname = usePathname();
  const comTriggerProps = { ...triggerProps, ...((pathname === href || items?.some(item => pathname.startsWith(item.href))) ? activeTriggerProps : {}) };
  return (
    <>
      {href ? <Link {...comTriggerProps} href={href}>{trigger}</Link> : <HoverMenuTrigger {...anchorProps} ref={ref} {...comTriggerProps}>
        {trigger}
        <Visible visible={!!items?.length}><ChevronDown width={18} height={18} className="ml-[8px]" /></Visible>
      </HoverMenuTrigger>}

      {Array.isArray(items) && items.length ? <ControlledMenu
        {...hoverProps}
        {...menuState}
        anchorRef={ref}
        onClose={() => toggle(false)}
      >
        {
          items?.map((item, index) => (
            <HoverMenuItem key={index}>
              <Link href={item.href} {...{ ...menuItemProps, ...(pathname === item.href ? activeMenuItemProps : {}) }}>{item.title}</Link>
            </HoverMenuItem>
          ))
        }
      </ControlledMenu> : null}
    </>
  );
};

const HoverMenuItem = MenuItem;
const HoverMenuTrigger = React.forwardRef<HTMLAnchorElement, React.ComponentPropsWithoutRef<'a'>>((props, ref) => (
  // eslint-disable-next-line jsx-a11y/anchor-has-content
  <a ref={ref} {...props} />
));
interface HoverMenuProps {
  triggerProps?: React.ComponentPropsWithoutRef<'a'>;
  // 选中的菜单样式
  activeTriggerProps?: React.ComponentPropsWithoutRef<'a'>;
  menuItemProps?: React.ComponentPropsWithoutRef<'a'>;
  // 选中的菜单样式
  activeMenuItemProps?: React.ComponentPropsWithoutRef<'a'>;
  menus: {
    trigger: string;
    href?: string;
    items?: {
      title: string;
      href: string;
    }[];
  }
}
export {
  HoverMenu,
  HoverMenuItem,
  HoverMenuTrigger,
};
