
import i18n, { Resource } from 'i18next';

import LanguageDetector from 'i18next-browser-languagedetector';
import { merge } from 'lodash-es';
import { useMemo } from 'react';
import { initReactI18next, useTranslation } from 'react-i18next';
import z from 'zod';
import { zodI18nMap } from 'zod-i18n-map';

import enZod from 'zod-i18n-map/locales/en/zod.json';
import frZod from 'zod-i18n-map/locales/fr/zod.json';
import ruZod from 'zod-i18n-map/locales/ru/zod.json';
import zhZod from 'zod-i18n-map/locales/zh-CN/zod.json';

const supportedLocales = [
  {
    title: '中文',
    href: 'zh',
  },
  {
    title: 'English',
    href: 'en',
  },
  // {
  //   title: 'Français',
  //   href: 'fr',
  // },
  // {
  //   title: 'русский',
  //   href: 'ru',
  // },
];
export const fallbackLng = 'en';
const supportedLngs = ['zh', 'en', 'fr', 'ru'] as const;
export type supportedLngsType = typeof supportedLngs[number];

export async function initI18n() {
  const resources = await requestI18n();
  // @ts-expect-error
  const locales = Object.keys(resources).filter(locale => resources[locale])
    .map(locale => locale as supportedLngsType);
  i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init(merge({
      supportedLngs: locales,
      fallbackLng,
      resources,
    }, {
      resources: supportedLngs.reduce((acc, lng) => {
        acc[lng] = {
          zod: {
            en: enZod,
            fr: frZod,
            ru: ruZod,
            zh: zhZod,
          }[lng],
        };
        return acc;
      }, {} as unknown as Record<supportedLngsType, Record<string, any>>),
    }));
  z.setErrorMap(zodI18nMap);
};

export async function requestI18n() {
  try {
    const fetchLocale = async (locale: string) => {
      const res = await fetch(`https://static.zxnum.com/locales/icua-official-site/${locale}.json?timer=${Date.now()}`);
      return res.json();
    };
    const lngsResults = await Promise.allSettled(supportedLngs.map(fetchLocale));
    const [zh, en, fr, ru] = lngsResults.map((lngsResult) => {
      const { status } = lngsResult;
      if (status === 'fulfilled') {
        return lngsResult.value as Resource;
      }
      return null as unknown as Resource;
    });
    return {
      zh,
      en,
      fr,
      ru,
    };
  } catch (error) {
    console.error('requestI18n error:', error);
    return Promise.reject(error);
  }
}


export function useLocale() {
  const { i18n } = useTranslation();
  return i18n.language as supportedLngsType;
}


// 获取支持的语言列表
export function useSupportedLngs() {
  const { i18n } = useTranslation();
  return i18n?.options?.supportedLngs as supportedLngsType[] ?? [];
}

// 获取支持的语言列表
export function useSupportedLocales() {
  const lngs = useSupportedLngs();
  return useMemo(() => supportedLocales.filter(locale => lngs.includes(locale.href as supportedLngsType)), [lngs]);
}
