
import { useTranslation } from 'react-i18next';

import { CosImage } from '@/components/ui/image';

export const Footer = () => {
  const { t } = useTranslation('Footer');
  return (
    <footer className="bg-main" id='footer'>
      <div className="flex ml-[196px] py-[83px] items-center">
        <CosImage src="/global/footer logo@2x.png" width={269} height={194} alt="logo" />
        <div className="w-[1px] h-[128px] bg-[#fff] mx-[30px]"></div>
        <div className="flex flex-col gap-[14px] text-lg">
          {/* <div className="flex items-center">
            <CosImage src="/home/telphone@2x.png" width={20} height={20} alt="telphone" className="mr-[8px]" />
            <span className="text-[#fff] ml-[10px]">{t('telphone')}</span>
          </div>
          <div className="flex items-center">
            <CosImage src="/home/postcode@2x.png" width={20} height={20} alt="telephone" className="mr-[8px]" />
            <span className="text-[#fff] ml-[10px]">{t('postcode')}</span>
          </div>
          <div className="flex items-center">
            <CosImage src="/home/email@2x.png" width={20} height={20} alt="email" className="mr-[8px]" />
            <span className="text-[#fff] ml-[10px]">
              <a href="mailto:" className="text-[#fff]">{t('email')}</a>
            </span>
          </div> */}
          <div className="text-base">
            <span className="text-[#fff]">{t('address')}</span>
          </div>
        </div>
      </div>
    </footer>
  );
};
